import React, {useEffect, useState} from "react";
import Seo from "../components/molecules/Seo";
import {graphql, Link} from "gatsby";
import ScrollHolder from "../components/molecules/ScrollHolder";
import Asset from "../components/atoms/Asset";
import AssetHolder from "../components/atoms/AssetHolder";
import styled from "styled-components";
import {useWindowSize} from "react-use";

const Holder = styled.div`
  .hero {
    margin-top: 160px;

    &:first-child {
      margin-top: 25vh;
    }

    @media ( ${props => props.theme.breakpoints.sm} ) {
      margin-top: 0 !important;
    }
  }

  .bleeds-left {
    h2 {
      margin-left: 20px;
    }
  }
`;

function IndexPage({data}) {
  // We need to sort all the individuals project preview assets into
  // and array so we can add them as children to the scrollholder
  const size = useWindowSize()
  const [mobile, setMobile] = useState(null);
  const [assets, setAssets] = useState([]);
  const [mobileAssets, setMobileAssets] = useState([]);

  useEffect(() => {
    let tempAssets = []
    let tempMobileAssets = []
    data.prismicHomepage.data.projects.forEach(project => {
      if (project.project.document) {
        project.project.document.data.preview_assets.forEach((preview_asset, i) => {
          tempAssets.push({
            asset: preview_asset,
            // the first preview asset for each child will have a title
            title: i === project.project.document.data.preview_assets.length - 1 ? project.project.document.data.title : null,
            uid: project.project.document.uid,
            hero: i === 0
          })
        })
      }
    })

    data.prismicHomepage.data.projects.forEach(project => {
      if (project.project.document) {
        if (project.project.document.data.mobile_preview_assets.length > 0) {
          project.project.document.data.mobile_preview_assets.forEach((mobile_preview_asset, i) => {
            tempMobileAssets.push({
              asset: mobile_preview_asset,
              // the first preview asset for each child will have a title
              title: i === project.project.document.data.mobile_preview_assets.length - 1 ? project.project.document.data.title : null,
              uid: project.project.document.uid,
              hero: i === 0
            })
          })
        } else {
          project.project.document.data.preview_assets.forEach((preview_asset, i) => {
            tempMobileAssets.push({
              asset: preview_asset,
              // the first preview asset for each child will have a title
              title: i === project.project.document.data.preview_assets.length - 1 ? project.project.document.data.title : null,
              uid: project.project.document.uid,
              hero: i === 0
            })
          })
        }
      }
    })

    setAssets(tempAssets)
    setMobileAssets(tempMobileAssets)
  }, [data]);

  useEffect(() => {
    if (size.width < 576) {
      setMobile(true)
    } else {
      setMobile(false)
    }
  }, [size.width, setMobile]);

  if (assets.length === 0) return null;

  return (
    <Holder>
      <Seo title="Home" keywords={[`gatsby`, `application`, `react`]} />

      {mobile && <ScrollHolder className="mobile-previews">
        {mobileAssets.map((asset, i) =>
          <AssetHolder key={i} asset={asset.asset} i={i}
                       className={"mobile_previews " + (asset.hero ? 'hero' : 'secondary')}>
            <Link to={`/project/${asset.uid}`}>
              <Asset asset={asset.asset} />
              {asset.title && <h2 style={{textAlign: "center"}}>{asset.title.text}</h2>}
            </Link>
          </AssetHolder>
        )}
      </ScrollHolder>}

      {!mobile && <ScrollHolder className="desktop-previews">
        {assets.map((asset, i) =>
          <AssetHolder key={i} asset={asset.asset} i={i}
                       className={"desktop_previews " + (asset.hero ? 'hero' : 'secondary')}>
            <Link to={`/project/${asset.uid}`}>
              <Asset asset={asset.asset} />
              {asset.title && <h2 style={{textAlign: "center"}}>{asset.title.text}</h2>}
            </Link>
          </AssetHolder>
        )}
      </ScrollHolder>}

    </Holder>
  );
}

export default IndexPage;

export const indexQuery = graphql`
    query {
        prismicHomepage {
            data {
                projects {
                    project {
                        document {
                            ... on PrismicProject {
                                id
                                uid
                                data {
                                    title {
                                        text
                                    }
                                    preview_assets {
                                        image {
                                            alt
                                            gatsbyImageData(
                                                layout: FULL_WIDTH 
                                                placeholder: BLURRED
                                                srcSetMaxWidth: 1400 
                                                width: 1400
                                                imgixParams: {q: 80}
                                            )
                                            
                                        }
                                        video {
                                            url
                                        }
                                        video_ratio_height
                                        video_ratio_width
                                        width
                                        left_position
                                        scroll_speed
                                        delay
                                    }
                                    mobile_preview_assets {
                                        image {
                                            alt
                                            gatsbyImageData(
                                                layout: FULL_WIDTH
                                                placeholder: BLURRED
                                                srcSetMaxWidth: 1400
                                                width: 1400
                                                imgixParams: {q: 80}
                                            )
                                        }
                                        video {
                                            url
                                        }
                                        video_ratio_height
                                        video_ratio_width
                                        width
                                        left_position
                                        scroll_speed
                                        delay
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;